import React from 'react';
import "../Divorces.scss"
import "../PhysicalUsers/PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const DisputesOrchard = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Przedsiębiorcy
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-9">
                        <h3 className="specialization-title mb-20">
                            SPORY SĄDOWE I ARBITRAŻ
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col justify-self-start w-full">
                        <p className="text-justify mb-5">Mamy świadomość, iż prowadzenie businessu jest obarczone
                            istotnym ryzykiem. Ryzyka nie da się uniknąć, jednak można skutecznie je ograniczyć,
                            pracując z prawnikami o odpowiednich kwalifikacjach oraz niezbędnym doświadczeniu
                            praktycznym. Naszym sukcesem jest sukces naszego klienta. Znajomość realiów rynkowych,
                            umiejętność odpowiedniego dobierania argumentów oraz wieloletnie doświadczenie wyniesione z
                            prowadzonych postępowań pozwala na obdarzenie nas zaufaniem.

                        </p>
                        <p className="text-justify mb-5">
                            Świadczymy kompleksowe wsparcie prawne na każdym etapie sporu: mediując, prowadząc
                            negocjacje, reprezentując przedsiębiorców i osoby fizyczne przed sądami powszechnymi jak i
                            sądownictwem polubownym. W ramach naszych kompetencji prowadzimy również spory z organami
                            administracji publicznej oraz reprezentujemy podmioty indywidualne i przedsiębiorców przed
                            sądami administracyjnymi.
                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Realizowaliśmy naszych klientów w sprawach
                            związanych z:</h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>naruszeniem dóbr osobistych poprzez publikacje telewizyjne i prasowe – przyznana kwota
                                zadośćuczynienia <strong><span className="text-4xl whitespace-nowrap">75 000 złotych</span></strong>;
                            </li>
                            <li>naruszeniem praw do znanej domeny internetowej przed Sądem Polubownym ds. Domen
                                Internetowych (Polska Izba Informatyki i Telekomunikacji);
                            </li>
                            <li>naruszeniem dóbr osobistych w sporze z powództwa znanego polityka, członka Krajowej
                                Rady Sądownictwa;
                            </li>
                            <li>naruszeniem dóbr osobistych celebryty w sporze z jednym największych koncernów
                                medialnych;
                            </li>
                            <li>nałożeniem obowiązku naprawienia szkody spowodowanego przestępstwem na szkodę Skarbu
                                Państwa w kwocie <strong><span className="text-4xl whitespace-nowrap">29 049 057,32 złotych</span></strong>.
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col lg:justify-self-start w-full">
                        <img src="/images/6.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};