import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const CriminalLow = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0 ">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                        PRAWO KARNE
                    </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col lg:justify-self-start w-full">
                        <p className="text-justify mb-5">Zespół KWKL posiada wieloletnie doświadczenie związane z
                            zapewnieniem pomocy prawnej z zakresu prawa karnego dla osób fizycznych. Nasi prawnicy
                            szczycą się ugruntowaną znajomością specyfiki postępowań karnych oraz potrzeb klientów na
                            każdym z etapów prowadzonego postępowania. Zapewniamy pomoc prawną w zakresie przestępstw
                            przeciwko życiu i zdrowiu, wypadków komunikacyjnych, przestępstw przeciwko mieniu
                            (kradzieże, włamania, oszustwa), dotyczących obrotu finansowego (sprawy karno – skarbowe).
                            Reprezentujemy klientów na etapie postępowania przygotowawczego (śledztwo, dochodzenie),
                            sądowego – reprezentacja przed sądem, jak
                            i postępowania wykonawczego (odroczenie wykonania kary pozbawienia wolności, warunkowe
                            przedterminowe zwolnienie, przerwa w wykonywaniu kary pozbawienia wolności).

                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Zrealizowaliśmy m.in. następujące projekty:</h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>obrona klienta w postępowaniu związanym z wprowadzaniem do obrotu znacznych ilości
                                substancji psychotropowych – łączna ilość ujawnionych substancji <strong><span
                                    className="text-4xl whitespace-nowrap">204 kg</span></strong>;
                            </li>
                            <li>obrona klienta w postępowaniu karnym związanym z uszczupleniem podatku VAT i PIT przy
                                czym kwota roszczeń odszkodowawczych wynosiła <strong><span className="text-4xl whitespace-nowrap">29 049 057,32 złotych</span></strong>;
                            </li>
                            <li>obrona klienta w postępowaniu dotyczącym nieprawidłowości w obrocie papierami
                                wartościowymi (manipulacja instrumentami finansowymi) – łączna wartość
                                szkody <strong><span className="text-4xl whitespace-nowrap">2
                                    000
                                    000,00 złotych</span></strong>;
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col justify-self-start w-full">
                        <img src="/images/2.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};