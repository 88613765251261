import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const JudicialProceeding = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                            POSTĘPOWANIE SĄDOWE I SPORY CYWILNE
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col lg:justify-self-start w-full">


                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col justify-self-start w-full">
                        <img src="/images/1.png" alt="osoby fizyczne"/>
                    </div>
                </div>
                <Navigation />
            </div>
        </>
    );
};