import React, {useEffect, useState} from 'react';
import "./Header.scss"
import {NavLink, useLocation, useNavigate} from "react-router-dom";

export const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLinkClick = () => {
        navigate('/zespol/jaroslaw-kowalewski');
        setIsOpen(false);
        setIsOpenSubMenu(false)
    }

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

    const onOpenMenuHandler = () => {
        setIsOpen(!isOpen);
    }
    const onOpenSubMenuHandler = () => {
        setIsOpenSubMenu(!isOpenSubMenu);
    }

    const [showButton, setShowButton] = useState(false);

    // function to handle scrolling
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        });
    }, []);

    // function to handle click on scroll button
    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <div className="header-container relative">
            <div className="flex justify-between items-center align-middle">
                <NavLink to="/">
                    <img src="/images/logo.png" alt="logo" className="lg:block hidden"/>
                    <img src="/images/small_logo.png" alt="logo" className="lg:hidden"/>
                </NavLink>
                <div className="lg:hidden">
                    {!isOpen ? <img src="/images/open-menu.png" alt="open menu" onClick={onOpenMenuHandler}/> :
                        <img src="/images/close-menu.png" onClick={onOpenMenuHandler} alt="open menu"/>}
                </div>

                <nav className="lg:flex hidden justify-between">
                    <ul className="flex">
                        <li className="group relative">
                            <p className={location.pathname.startsWith("/specjalizacje/") ? "active" : ""}>Specjalizacje</p>
                            <ul className="absolute hidden group-hover:block bg-white">
                                <li className="mb-4">
                                    <NavLink to="specjalizacje/osoby-fizyczne">OSOBY FIZYCZNE</NavLink>
                                </li>
                                <li className="mb-4">
                                    <NavLink to="specjalizacje/przedsiebiorcy">PRZEDSIĘBIORCY</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="vl"></li>
                        <li className="group">
                            <p className={location.pathname.startsWith('/zespol') ? 'active' : ''}
                               onClick={handleLinkClick}>Zespół</p>
                        </li>
                        <li className="vl"></li>
                        <li className="group">
                            <NavLink to="/rozwody">Rozwody</NavLink>
                        </li>
                        <li className="vl"></li>
                        <li className="group pr-0">
                            <NavLink to="/kontakt">Kontakt</NavLink>
                        </li>
                    </ul>
                </nav>

            </div>
            {isOpen &&
                <nav className="mobile-menu flex justify-between items-start lg:hidden">
                    <ul>
                        <li className="flex flex-col justify-center items-center">
                            <div className="flex items-center" onClick={onOpenSubMenuHandler}>
                                <p
                                    className={location.pathname.startsWith("/specjalizacje/") ? "active" : ""}>Specjalizacje</p>
                                <div>
                                    {!isOpenSubMenu ?
                                        <img src="/images/close-submenu.png" alt="open" className="ml-14"/> :
                                        <img src="/images/open-submenu.png" alt="open" className="ml-14"/>}
                                </div>
                            </div>
                            {isOpenSubMenu &&
                                <ul className="relative mt-12">
                                    <li className="">
                                        <NavLink onClick={() => {
                                            setIsOpen(false);
                                            setIsOpenSubMenu(false)
                                        }} to="specjalizacje/osoby-fizyczne">OSOBY FIZYCZNE</NavLink>
                                    </li>
                                    <li className="mb-0">
                                        <NavLink onClick={() => {
                                            setIsOpen(false);
                                            setIsOpenSubMenu(false)
                                        }} to="specjalizacje/przedsiebiorcy">PRZEDSIĘBIORCY</NavLink>
                                    </li>
                                </ul>}
                        </li>
                        <li>
                            <p className={location.pathname.startsWith('/zespol') ? 'active' : ''}
                               onClick={handleLinkClick}>Zespół</p>
                        </li>
                        <li>
                            <NavLink onClick={() => {
                                setIsOpen(false);
                                setIsOpenSubMenu(false);
                            }} to="/rozwody">Rozwody</NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => {
                                setIsOpen(false);
                                setIsOpenSubMenu(false);
                            }} to="/kontakt">Kontakt</NavLink>
                        </li>
                    </ul>
                    <div className="flex flex-col items-center">
                        <a href="https://www.facebook.com/KWKLLegal/" target="_blank" rel="noreferrer" className="mb-10"><img src="/images/FACEBOK 2.png" alt="facebook"/></a>
                        <a href="https://twitter.com/adw_Kowalewski" target="_blank" rel="noreferrer" className="mb-10"><img src="/images/TWITTER 2.png" alt="twitter"/></a>
                        <a href="https://www.linkedin.com/company/kwkl-kowalewski-legal/?originalSubdomain=pl" target="_blank" rel="noreferrer" className="mb-10"><img src="/images/LINKEDIN 2.png" alt="linkedid"/></a>
                    </div>
                </nav>
            }
            {showButton && (
                <div className="btn-up lg:hidden" onClick={handleClick}>
                    <img src="/images/up.png" alt="up" onClick={handleClick}/>
                </div>
            )}
        </div>
    );
};