import React, {useEffect, useState} from 'react';
import "./MainPage.scss"
import {NavLink} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Trust} from "../components/Trust";

export const MainPage = () => {

    const handleUserChange = () => {
        window.scrollTo(0, 0);
    };


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };




    const slides = [
        {
            id: 1,
            title: "Młody zespół",
            content: "doświadczonych prawników",
        },
        {
            id: 2,
            title: "Największa kancelaria",
            content: "w Wawrze",
        },
        {
            id: 3,
            title: "82 %",
            content: "Wygranych postępowań",
        },
        {
            id: 4,
            title: "44 lata",
            content: "Suma doświadczeń prawników",
        },
        {
            id: 5,
            title: "29 049 057, 32 zł",
            content: "Najwyższa wywalczona kwota dla klienta",
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((currentSlide) =>
                currentSlide === slides.length - 1 ? 0 : currentSlide + 1
            );
        }, 3000);
        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <div className="main-page-container flex flex-col">
            <div>
                <img src="/images/zespol_kwkl.png" alt="baner"/>
                <div className="slider-container">
                    {slides.map((slide, index) => (
                        <div
                            className={
                                index === currentSlide
                                    ? "slide slide--current"
                                    : "slide slide--hidden"
                            }
                            key={slide.id}
                        >
                            <p className="description-title">{slide.title}</p>
                            <p className="description-text">{slide.content}</p>
                        </div>
                    ))}
                </div>
                <div className="team-wrapper" id="team">
                    <div className="flex justify-center items-center">
                        <hr/>
                        <h3 className="team-title">
                            Poznaj nasz zespół
                        </h3>
                        <hr/>
                    </div>
                    <div className="lg:hidden">
                        <Slider {...settings}>
                            <div className="cards-wrapper">
                                <div className="card-wrapper flex flex-col justify-center items-center">
                                    <div>
                                        <img src="/images/jaroslaw_kowalewski.png" alt="kowaleski jarosław"/>
                                    </div>
                                    <div className="card-name">
                                        <h4>Jarosław Kowalewski</h4>
                                        <span className="prof-title">adwokat <br/> <span
                                            className="invisible">kwkl</span> </span>
                                        <p className="card-contact">
                                            mail: <strong>j.kowalewski@kwkl.pl</strong> <br/>
                                            tel.: <strong>501 039 699</strong>
                                        </p>
                                        <div className="flex items-center w-full">
                                            <hr/>
                                            <NavLink to="/zespol/jaroslaw-kowalewski"
                                                     onClick={() => handleUserChange('jaroslaw-kowalewski')}
                                                     className="btn-more">więcej >></NavLink>
                                            <hr style={{maxWidth: 30}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cards-wrapper">
                                <div className="card-wrapper flex flex-col justify-center items-center">
                                    <div>
                                        <img src="/images/piotr_kujawa.png" alt="kujawa piotr"/>
                                    </div>
                                    <div className="card-name">
                                        <h4>Piotr Kujawa</h4>
                                        <span className="prof-title">radca prawny <br/> <span
                                            className="invisible">kwkl</span> </span>
                                        <p className="card-contact">
                                            mail: <strong>p.kujawa@kwkl.pl</strong> <br/>
                                            tel.: <strong>603 862 304</strong>
                                        </p>
                                        <div className="flex items-center w-full">
                                            <hr/>
                                            <NavLink to="/zespol/piotr-kujawa"
                                                     onClick={() => handleUserChange('piotr-kujawa')}
                                                     className="btn-more">więcej >></NavLink>
                                            <hr style={{maxWidth: 30}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cards-wrapper">
                                <div className="card-wrapper flex flex-col justify-center items-center">
                                    <div>
                                        <img src="/images/pawel_balcerkiewicz.png" alt="balcerkiewicz paweł"/>
                                    </div>
                                    <div className="card-name">
                                        <h4>Paweł Balcerkiewicz </h4>
                                        <span className="prof-title">adwokat <br/> <span
                                            className="invisible">kwkl</span> </span>
                                        <p className="card-contact">
                                            mail: <strong>p.balcerkiewicz@kwkl.pl</strong> <br/>
                                            tel.: <strong>730 771 732</strong>
                                        </p>
                                        <div className="flex items-center w-full">
                                            <hr/>
                                            <NavLink to="/zespol/pawel-balcerkiewicz"
                                                     onClick={() => handleUserChange('pawel-balcerkiewicz')}
                                                     className="btn-more">więcej >></NavLink>
                                            <hr style={{maxWidth: 30}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cards-wrapper">
                                <div className="card-wrapper flex flex-col justify-center items-center">
                                    <div>
                                        <img src="/images/aleksandra_karczynska.png" alt="karczyńska aleksandra"/>
                                    </div>
                                    <div className="card-name">
                                        <h4>Aleksandra Karczyńska</h4>
                                        <span className="prof-title text-center">psycholog, psychoterapeuta, <br/> mediator rodzinny</span>
                                        <p className="card-contact">
                                            mail: <strong>a.karczynska@kwkl.pl</strong> <br/>
                                            tel.: <strong>501 704 347</strong>
                                        </p>
                                        <div className="flex items-center w-full">
                                            <hr/>
                                            <NavLink to="/zespol/aleksandra-karczynska"
                                                     onClick={() => handleUserChange('aleksandra-karczynska')}
                                                     className="btn-more">więcej >></NavLink>
                                            <hr style={{maxWidth: 30}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cards-wrapper">
                                <div className="card-wrapper flex flex-col justify-center items-center">
                                    <div>
                                        <img src="/images/mikolaj_wojciechowski.png" alt="mikołaj wojciechowski "/>
                                    </div>
                                    <div className="card-name">
                                        <h4>Mikołaj Wojciechowski </h4>
                                        <span className="prof-title text-center">adwokat <br/> <span
                                            className="invisible">kwkl</span> </span>
                                        <p className="card-contact">
                                            mail: <strong>m.wojciechowski@kwkl.pl</strong> <br/>
                                            tel.: <strong>694 938 328</strong>
                                        </p>
                                        <div className="flex items-center w-full">
                                            <hr/>
                                            <NavLink to="/zespol/mikolaj-wojciechowski"
                                                     onClick={() => handleUserChange('mikolaj-wojciechowski')}
                                                     className="btn-more">więcej >></NavLink>
                                            <hr style={{maxWidth: 30}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="cards-wrapper lg:flex hidden">
                        <div className="card-wrapper">
                            <div>
                                <img src="/images/jaroslaw_kowalewski.png" alt="kowaleski jarosław"/>
                            </div>
                            <div className="card-name">
                                <h4>Jarosław Kowalewski</h4>
                                <span className="prof-title">adwokat <br/> <span
                                    className="invisible">kwkl</span> </span>
                                <p className="card-contact">
                                    mail: <strong>j.kowalewski@kwkl.pl</strong> <br/>
                                    tel.: <strong>501 039 699</strong>
                                </p>
                                <div className="flex items-center w-full">
                                    <hr/>
                                    <NavLink to="/zespol/jaroslaw-kowalewski"
                                             onClick={() => handleUserChange('jaroslaw-kowalewski')}
                                             className="btn-more">więcej >></NavLink>
                                    <hr style={{maxWidth: 30}}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-wrapper">
                            <div>
                                <img src="/images/piotr_kujawa.png" alt="kujawa piotr"/>
                            </div>
                            <div className="card-name">
                                <h4>Piotr Kujawa</h4>
                                <span className="prof-title">radca prawny <br/> <span className="invisible">kwkl</span> </span>
                                <p className="card-contact">
                                    mail: <strong>p.kujawa@kwkl.pl</strong> <br/>
                                    tel.: <strong>603 862 304</strong>
                                </p>
                                <div className="flex items-center w-full">
                                    <hr/>
                                    <NavLink to="/zespol/piotr-kujawa" onClick={() => handleUserChange('piotr-kujawa')}
                                             className="btn-more">więcej >></NavLink>
                                    <hr style={{maxWidth: 30}}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-wrapper">
                            <div>
                                <img src="/images/pawel_balcerkiewicz.png" alt="balcerkiewicz paweł"/>
                            </div>
                            <div className="card-name">
                                <h4>Paweł Balcerkiewicz </h4>
                                <span className="prof-title">adwokat <br/> <span
                                    className="invisible">kwkl</span> </span>
                                <p className="card-contact">
                                    mail: <strong>p.balcerkiewicz@kwkl.pl</strong> <br/>
                                    tel.: <strong>730 771 732</strong>
                                </p>
                                <div className="flex items-center w-full">
                                    <hr/>
                                    <NavLink to="/zespol/pawel-balcerkiewicz"
                                             onClick={() => handleUserChange('pawel-balcerkiewicz')}
                                             className="btn-more">więcej >></NavLink>
                                    <hr style={{maxWidth: 30}}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-wrapper">
                            <div>
                                <img src="/images/mikolaj_wojciechowski.png" alt="mikołaj wojciechowski "/>
                            </div>
                            <div className="card-name">
                                <h4>Mikołaj Wojciechowski </h4>
                                <span className="prof-title text-center">adwokat <br/> <span
                                    className="invisible">kwkl</span> </span>
                                <p className="card-contact">
                                    mail: <strong>m.wojciechowski@kwkl.pl</strong> <br/>
                                    tel.: <strong>694 938 328</strong>
                                </p>
                                <div className="flex items-center w-full">
                                    <hr/>
                                    <NavLink to="/zespol/mikolaj-wojciechowski"
                                             onClick={() => handleUserChange('mikolaj-wojciechowski')}
                                             className="btn-more">więcej >></NavLink>
                                    <hr style={{maxWidth: 30}}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-wrapper">
                            <div>
                                <img src="/images/aleksandra_karczynska.png" alt="karczyńska aleksandra"/>
                            </div>
                            <div className="card-name">
                                <h4>Aleksandra Karczyńska</h4>
                                <span className="prof-title text-center">psycholog, psychoterapeuta, <br/> mediator rodzinny</span>
                                <p className="card-contact">
                                    mail: <strong>a.karczynska@kwkl.pl</strong> <br/>
                                    tel.: <strong>501 704 347</strong>
                                </p>
                                <div className="flex items-center w-full">
                                    <hr/>
                                    <NavLink to="/zespol/aleksandra-karczynska"
                                             onClick={() => handleUserChange('aleksandra-karczynska')}
                                             className="btn-more">więcej >></NavLink>
                                    <hr style={{maxWidth: 30}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Trust />
            </div>

        </div>
    );
};
