import React from 'react';
import "../Divorces.scss"
import "../PhysicalUsers/PrhysicalUsers.scss"
import {useNavigate} from "react-router-dom";

export const Firms = () => {

    const navigate = useNavigate();
    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Przedsiębiorcy
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-10 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                        WSPARCIE PRAWNE PRZEDSIĘBIORCÓW
                    </h3>
                    </div>
                    <div className="lg:col-span-1"></div>
                    <div className="lg:col-span-5"></div>
                    <div className="lg:col-span-4 col-span-10 flex flex-col lg:justify-self-start w-full">


                        <p className="text-justify mb-5">KWKL Kowalewski Legal została powołana przez zespół
                            doświadczonych prawników, specjalizujących się w obsłudze podmiotów gospodarczych. Od wielu
                            lat doradzamy przedsiębiorcom, co czyni nas ekspertami znającymi potrzeby naszych klientów
                            oraz panujące realia rynkowe.


                        </p>

                        <p className="text-justify mb-10">Nasze doświadczenie i kompetencje zawodowe budowaliśmy jako
                            doradcy wewnętrzni (in-house)
                            oraz jako niezależni prawnicy, co pozwala nam doradzać w oparciu o doświadczenie wyniesione
                            zarówno z poziomu przedsiębiorcy jak i zewnętrznego eksperta, obiektywnie oceniającego
                            sytuację klienta. Praca dla międzynarodowych koncernów farmaceutycznych oraz doradztwo w
                            ramach zespołów farmaceutycznych czołowych kancelarii prawnych daje gwarancje skutecznego, a
                            zarazem bezpiecznego rozwiązania Państwa problemów.</p>
                        <p className="text-justify mb-10">Posiadamy młody a zarazem doświadczony zespół praktyków, który
                            zapewni biznesowe podejście
                            do rozwiązywania zagadnień prawnych oraz elastycznie doradzi, tak aby mogli Państwo podjąć
                            efektywne działania zmierzające ku zwiększaniu przychodów oraz rozwojowi, jednocześnie
                            zachowując bezpieczeństwo prawne.</p>
                        <p className="text-justify mb-10">Praktyka KWKL Kowalewski Legal obejmuje obszary prawa
                            gospodarczego, ze szczególnym
                            uwzględnieniem prawa farmaceutycznego, medycyny, prawa żywnościowego oraz tworzenia umów w
                            obrocie handlowym.</p>

                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-5 col-span-10 lg:justify-self-end w-full">
                        <button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => {
                                    navigate('/specjalizacje/przedsiebiorcy/farmacja-i-compliance');
                                    window.scrollTo(0, 0);
                                }}>
                            <div>FARMACJA <span className="mx-5">|</span> COMPLIANCE</div>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => {
                                    navigate('/specjalizacje/przedsiebiorcy/spory-sadowe-i-arbitraz');
                                    window.scrollTo(0, 0);
                                }}>
                            <span>SPORY SĄDOWE I ARBITRAŻ</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => {
                                    navigate('/specjalizacje/przedsiebiorcy/windykacja-i-wywiad-gospodarczy');
                                    window.scrollTo(0, 0);
                                }}>
                            <span>WINDYKACJA I WYWIAD GOSPODARCZY</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => {
                                    navigate('/specjalizacje/przedsiebiorcy/prawo-karne-gospodarcze');
                                    window.scrollTo(0, 0);
                                }}>
                            <span>PRAWO KARNE GOSPODARCZE</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        {/* <button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => navigate('/specjalizacje/przedsiebiorcy/prawo-kontraktowe')}>
                            <span>PRAWO KONTRAKTOWE</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>*/}
                        <button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => {
                                    navigate('/specjalizacje/przedsiebiorcy/prawo-sportowe-i-sport-management');
                                    window.scrollTo(0, 0);
                                }}>
                            <span>PRAWO SPORTOWE I SPORT MANAGEMENT</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        {/*<button className="btn-nav flex justify-between items-center mb-10"
                                onClick={() => navigate('/specjalizacje/przedsiebiorcy/wsparcie-prawne-dla-fundacji-i-stowarzyszen')}>
                            <span>WSPARCIE PRAWNE DLA FUNDACJI I STOWARZYSZEŃ</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>*/}
                    </div>

                </div>
            </div>
        </>
    );
};