import React from 'react';
import "../Divorces.scss"
import "../PhysicalUsers/PrhysicalUsers.scss"
import {useNavigate} from "react-router-dom";
import {Navigation} from "../../components/Navigation";

export const SportLow = () => {
    const navigate = useNavigate();
    const handleClickPhysical = () => {
        navigate('/specjalizacje/osoby-fizyczne');
        window.scrollTo(0, 0);
    }
    const handleClickFirms = () => {
        navigate('/specjalizacje/przedsiebiorcy');
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Przedsiębiorcy
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-9">
                        <h3 className="specialization-title mb-20">
                            PRAWO SPORTOWE I SPORT MANAGEMENT
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col justify-self-start w-full">
                        <p className="text-justify mb-5">Sport nie jest dla nas jedynie rodzajem aktywności fizycznej a
                            raczej zawodem, działalnością businessową, sposobem zarobkowania. Sport w profesjonalnym
                            wymiarze to także ogromny business, z którym powiązane są rozpoznawalne na globalnym rynku
                            marki. Prawo sportowe to nie tylko sportowcy, trenerzy, związki sportowe, ale również
                            infrastruktura sportowa, kontrakty reklamowe, transfery, własność intelektualna.

                        </p>
                        <p className="text-justify mb-5">
                            Oferujemy Państwu zespół doświadczonych prawników praktyków, który w sposób bezpieczny i
                            optymalny pomoże rozwiązać bieżące problemy oraz zaplanować odpowiednie działania w
                            dziedzinie sportu oraz specyfiki prawa sportowego oraz związanych z nim regulacji prawnych.
                        </p>
                        <p className="text-justify mb-5">
                            Świadczymy usługi dla klubów oraz związków sportowych, zarówno jako eksperci zewnętrzni jak
                            i członkowie organów statutowych organizacji sportowych.
                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Prowadziliśmy projekty związane z: </h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>doradztwem strategicznym dla związków sportowych,
                            </li>
                            <li>pozyskiwaniem dofinansowania zewnętrznego dla klubów i związków sportowych,
                            </li>
                            <li>szkoleniami obejmującymi zwalczanie korupcji oraz dopingu w sporcie,
                            </li>
                            <li>reprezentowaniem związku sportowego w sporze z zawodnikiem kadry narodowej,</li>
                            <li>wsparciem prawnym i negocjowaniem umowy sponsorskiej dla zawodnika kadry narodowej.
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col lg:justify-self-start w-full">
                        <img src="/images/5.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};