import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const Declines = () => {


    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0 ">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                        SPADKI I POSTĘPOWANIA SPADKOWE
                    </h3></div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col lg:justify-self-start w-full">


                        <p className="text-justify mb-5">Prawnicy KWKL posiadają wieloletnie doświadczenie z zakresu
                            spraw spadkowych oraz postępowań
                            z elementem spadku. Reprezentujemy interesy naszych klientów na wszystkich etapach
                            związanych
                            z nabyciem spadku zarówno w drodze testamentu jak i dziedziczenia ustawowego. Prowadzimy
                            sprawy z zakresu stwierdzenia nabycia spadku; dochodzenia roszczeń osób uprawnionych do
                            zachowku jak
                            i roszczeń zapisobierców; działu spadku i rozliczeń między spadkobiercami; umów dotyczących
                            spadku.

                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Dotychczas zrealizowaliśmy projekty związane
                            z: </h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>wykazaniem roszczeń do zachowku w przypadku darowizny całego majątku wykonanej za
                                życia spadkobiercy – kwota roszczenia około <strong><span className="text-4xl whitespace-nowrap">360 000,00 złotych</span></strong>;
                            </li>
                            <li>podziałem majątku dorobkowego małżonków – łączna kwota roszczeń około <strong><span className=" whitespace-nowrap text-4xl">6 000 000,00 złotych</span></strong>;
                            </li>
                            <li>audytu nieruchomości będącej przedmiotem postępowania spadkowego – wartość około <strong><span className="whitespace-nowrap text-4xl">4 600 000,00 złotych</span></strong>;
                            </li>
                            <li>działem spadku (zniesienie współwłasności) pomiędzy spadkobiercami – wartość roszczeń około <strong><span className="whitespace-nowrap text-4xl">1 400 000,00 złotych</span></strong>; </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col justify-self-start w-full">
                        <img src="/images/1.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};