import React from 'react';
import "../Divorces.scss"
import "../PhysicalUsers/PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const VindicationFirms = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Przedsiębiorcy
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-9">
                        <h3 className="specialization-title mb-20">
                            WINDYKACJA I WYWIAD GOSPODARCZY
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col justify-self-start w-full">


                        <p className="text-justify mb-5">W ramach KWKL Kowalewski rozwinęliśmy dla Państwa dedykowaną
                            przedsiębiorcom praktykę windykacji oraz wywiadu gospodarczego.


                        </p>
                        <p className="text-justify mb-5"> Oferujemy doradztwo prawne polegające na skutecznym
                            odzyskiwaniu należności od Państwa
                            kontrahentów. Nasze działania realizujemy poprzez prowadzenie postępowań sądowych (uzyskanie
                            tytułu egzekucyjnego), jak również egzekucyjnych w ramach postępowania prowadzonego przez
                            komornika.</p>
                        <p className="text-justify mb-5">W ramach zleconych nam działań dokonujemy również monitoringu
                            płatności (kontrolowanie
                            postępów w spłacie zobowiązań dłużnika), co zabezpiecza Państwa przed powstaniem należności
                            przeterminowanych oraz tych zagrożonych przedawnieniem.</p>
                        <p className="text-justify mb-5"> Dysponujemy oprogramowaniem i dostępem do aplikacji
                            pozwalających na ocenę zasadności
                            podejmowanych działań prawnych, stanu zobowiązań dłużnika, ciążących na nim zobowiązań i
                            roszczeń. Ta wiedza pozwala nam na sprawne przeprowadzenie procesu odzyskania środków
                            pieniężnych oraz mienia.</p>
                        <p className="text-justify mb-5">Przy udziale profesjonalnego podmiotu zewnętrznego dokonujemy
                            ustaleń zmierzających do
                            wykrycia bądź wyjawienia majątku dłużnika, jego obecnej sytuacji majątkowej, posiadanych
                            ruchomości oraz nieruchomości.</p>
                        <p className="text-justify mb-5">Prowadzimy działania obejmujące współpracę z wywiadownią
                            gospodarczą, dokonującą ustaleń
                            sytuacji majątkowej oraz faktycznej Państwa kontrahenta. Prawidłowe ustalenie kondycji
                            finansowej partnera bussinesowego, jego dorobku, powiązań kapitałowych, renomy, oceny
                            wiarygodności rynkowej, informacji dotyczącej realizacji zobowiązań, będzie pomocne w
                            podjęciu racjonalnej decyzji o współpracy handlowej. W razie stosownej potrzeby generujemy
                            raporty obejmujące działania detektywistyczne oraz oceny wiarygodności kontrahenta</p>
                        <p className="text-justify mb-5">W ramach współpracy dysponujemy również zespołem windykatorów
                            terenowych.</p>

                        <h2 className="divorces-title mb-10 mt-10"> Zrealizowane projekty: </h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>odzyskanie ukrywanego przed egzekucją mienia w postaci autokaru do poboru krwi – łączna
                                kwota roszczeń około <strong><span
                                    className="text-4xl whitespace-nowrap">500 000,00 złotych</span></strong>;
                            </li>
                            <li>uznanie za bezskuteczne czynności w ramach tzw. skargi pauliańskiej, polegające na
                                realizowaniu przez dłużnika transakcji z pokrzywdzeniem wierzyciela – łączna kwota
                                roszczeń około <strong><span
                                    className="text-4xl whitespace-nowrap">1 000 000,00 złotych</span></strong>;
                            </li>
                            <li>ustalenie składu majątku dłużnika podejmującego działania związane z jego ukrywaniem
                                i próbą uniknięcia egzekucji – kwota ujawnionego mienia
                                <strong><span className="text-4xl whitespace-nowrap"> 2 600 000,00 złotych</span></strong>.
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 lg:flex hidden justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col lg:justify-self-start w-full">
                        <img src="/images/3.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};