import React from 'react';
import "./Contact.scss"
import {Trust} from "../components/Trust";

export const Contact = () => {
    const numb = 531580793;
    const numbFmt = new Intl.NumberFormat('ru-RU').format(numb);
    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Kontakt
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-4 lg:px-48 px-0">
                    <div className="lg:col-span-2 col-span-7 flex flex-col justify-self-start w-full">
                            <span className="contact-title lg:mb-14 mb-8 lg:mt-0 mt-8">
                                KWKL Kowalewski Legal
                            </span>
                        <span className="mb-1 contact-text">
                               <strong>ul. Żegańska 16 lok. 2</strong>
                            </span>
                        <span className="mb-5 contact-text">
                                <strong>04-713 Warszawa</strong>
                            </span>

                        <div className="mt-10 mb-10">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9780.347199601465!2d21.1730515!3d52.2054701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ed301440e5555%3A0xc0f611b2b8fa96ae!2sKWKL%20Kowalewski%20Legal%20Adwokat%20Jaros%C5%82aw%20Kowalewski!5e0!3m2!1spl!2spl!4v1678712587594!5m2!1spl!2spl"
                                allowFullScreen="" loading="lazy" className="w-5/6 lg:block hidden" height="175px" title="kwkl"
                                referrerPolicy="no-referrer-when-downgrade">

                            </iframe>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9780.347199601465!2d21.1730515!3d52.2054701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ed301440e5555%3A0xc0f611b2b8fa96ae!2sKWKL%20Kowalewski%20Legal%20Adwokat%20Jaros%C5%82aw%20Kowalewski!5e0!3m2!1spl!2spl!4v1678712587594!5m2!1spl!2spl"
                                allowFullScreen="" loading="lazy" className="w-full lg:hidden" height="175px" title="kwkl"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                            <div className="mt-5">
                                <a href="https://www.google.com/maps/dir//KWKL+Kowalewski+Legal,+%C5%BBega%C5%84ska+16%2Flok+2,+04-713+Warszawa/@52.2054734,21.1708628,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x471ed301440e5555:0xc0f611b2b8fa96ae!2m2!1d21.1730515!2d52.2054701!3e0"
                                   target="_blank" rel="noreferrer">
                                    <strong>Wyznacz trasę >></strong>
                                </a>
                            </div>
                        </div>

                        <span className="mb-1 contact-text">
                                Zadzwoń do nas: <a href="tel:531 580 793"
                                                   className="contact-link ml-5"><strong>{numbFmt}</strong></a>
                            </span>
                        <span className="contact-text">
                                Napisz do nas: <a href="mailto:biuro@kwkl.pl"
                                                  className="contact-link lg:ml-14 ml-12"><strong>biuro@kwkl.pl</strong></a>
                            </span>
                        <div className="lg:hidden find-us-wrapper mt-4">
                            <span>Znajdź nas:</span>
                            <div className="flex mb-5 mt-5">
                                <div className="pt-1 mr-4">
                                    <img src="/images/FACEBOK%201.png" alt="facebook"/>
                                </div>
                                <a href="https://www.facebook.com/KWKLLegal/" target="_blank" rel="noreferrer" className="mr-20">FACEBOOK</a>
                            </div>
                            <div className="flex mb-5">
                                <div className="pt-1 mr-4">
                                    <img src="/images/TWITTER%201.png" alt="twitter"/>
                                </div>
                                <a href="https://twitter.com/adw_Kowalewski" className="mr-20" target="_blank" rel="noreferrer">TWITTER</a>
                            </div>
                            <div className="flex">
                                <div className="pt-1 mr-4">
                                    <img src="/images/LINKEDIN%201.png" alt="linkedin"/>
                                </div>
                                <a href="https://www.linkedin.com/company/kwkl-kowalewski-legal/?originalSubdomain=pl" target="_blank" rel="noreferrer">LINKEDIN</a>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-2 lg:order-last order-first col-span-7 lg:justify-self-end lg:ml-16 flex flex-col justify-end">
                        <img src="/images/KWKL.png" alt="kwkl" className="h-auto max-w-full"/>
                        <div className="lg:flex 2xl:flex-row flex-col hidden find-us-wrapper mt-12">
                            <span className="whitespace-nowrap mb-5">Znajdź nas:</span>
                            <div className="flex mb-5">
                                    <img src="/images/FACEBOK%201.png" alt="facebook" style={{width: 25, height: 25}} className="mt-2 mr-3"/>
                                <a href="https://www.facebook.com/KWKLLegal/" target="_blank" rel="noreferrer" className="mr-20">FACEBOOK</a>
                            </div>
                            <div className="flex mb-5">

                                    <img src="/images/TWITTER%201.png" alt="twitter" style={{width: 25, height: 25}} className="mt-2 mr-3"/>

                                <a href="https://twitter.com/adw_Kowalewski" className="mr-20" target="_blank" rel="noreferrer">TWITTER</a>
                            </div>
                            <div className="flex mb-5">

                                    <img src="/images/LINKEDIN%201.png" alt="linkedin" style={{width: 25, height: 25}} className="mt-2 mr-3"/>

                                <a href="https://www.linkedin.com/company/kwkl-kowalewski-legal/?originalSubdomain=pl" target="_blank" rel="noreferrer">LINKEDIN</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
           <Trust />
        </>
    );
};