import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const Protection = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                            OCHRONA DÓBR OSOBISTYCH I WŁASNOŚCI INTELEKTUALNEJ
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col lg:justify-self-start w-full">


                        <p className="text-justify mb-5">Prowadzimy projekty związane z ochroną dóbr osobistych
                            (naruszenie czci, godności, dobrego imienia) osób fizycznych jak i renomy oraz reputacji
                            przedsiębiorców. Reprezentowaliśmy klientów w sporach sądowych o ochronę wizerunku, jak
                            również w postępowaniach obejmujących naruszenie dóbr osobistych celebrytów w drodze
                            publikacji prasowych i telewizyjnych. Nasi prawnicy doradzali klientom w sporach dotyczących
                            naruszenia własności intelektualnej poprzez bezprawne korzystanie z dorobku naukowego,
                            utworów muzycznych i audiowizualnych. Prowadziliśmy również projekty dotyczące ochrony praw
                            do domen internetowych i aplikacji mobilnych.


                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Reprezentowaliśmy klientów w sporach
                            dotyczących: </h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>naruszenia dóbr osobistych poprzez publikacje telewizyjne i prasowe – przyznana kwota
                                zadośćuczynienia <strong><span
                                    className="text-4xl whitespace-nowrap">75 000 złotych</span></strong>;
                            </li>
                            <li>naruszenia praw do znanej domeny internetowej przed Sądem Polubownym ds. Domen
                                Internetowych (Polska Izba Informatyki i Telekomunikacji);
                            </li>
                            <li>naruszenia dóbr osobistych w sporze z powództwa znanego polityka, członka Krajowej Rady
                                Sądownictwa;
                            </li>
                            <li>
                                naruszenia dóbr osobistych osoby publicznej w sporze z jednym największych koncernów
                                medialnych;
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col justify-self-start w-full">
                        <img src="/images/2.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};