import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {useNavigate} from "react-router-dom";

export const PhysicalUsers = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-10 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                            WSPARCIE PRAWNE DLA OSÓB FIZYCZNYCH I
                            KLIENTÓW INDYWIDUALNYCH
                        </h3>
                    </div>
                    <div className="lg:col-span-1"></div>
                    <div className="lg:col-span-5"></div>
                    <div className="lg:col-span-4 col-span-10 flex flex-col justify-self-start w-full">


                        <p className="text-justify mb-5">Oferujemy Państwu kompleksową obsługę prawną osób fizycznych.
                            Nasi prawnicy posiadają
                            niezwykle bogate doświadczenie w pracy z klientem indywidualnym. Prowadziliśmy szereg
                            postępowań obejmujących sprawy osób fizycznych, firm rodzinnych oraz małych i średnich
                            przedsiębiorców. Doradzamy w sposób efektywny i rzeczowy, kierując się w pierwszym rzędzie
                            zasadą bezkonfliktowego rozwiązywania sporów.
                        </p>

                        <p className="text-justify mb-10">Nasze doradztwo obejmuje sprawy cywilne (spory sądowe),
                            rodzinne jak i udział w
                            postępowaniach karnych. Prowadziliśmy sprawy dotyczące podziałów majątku dorobkowego
                            małżonków, zniesienia współwłasności, spadków oraz rozliczeń między spadkobiercami. W
                            praktyce zajmujemy się również sprawami dotyczącymi nieruchomości (sprzedaż, zakup,
                            ustalanie stanu prawnego), rozwodów i postępowań około rozwodowych (alimenty, opieka nad
                            dziećmi, rozliczenia majątkowe małżonków).</p>

                        <h2 className="divorces-title mb-10"> Zrealizowaliśmy m.in. następujące projekty:</h2>

                        <ul className="scope mb-20 text-justify lg:ml-5 ml-0">
                            <li>wykazanie roszczeń do zachowku w przypadku darowizny całego majątku wykonanej za życia
                                spadkobiercy – kwota roszczenia około <strong><span className="text-4xl whitespace-nowrap">360 000,00 złotych</span></strong>
                            </li>
                            <li>podział majątku dorobkowego małżonków – łączna kwota roszczeń około <strong><span
                                className="text-4xl whitespace-nowrap">6 000
                                000,00 złotych</span></strong></li>
                            <li>audyt prawny nieruchomości objętej roszczeniami zasiedzeniowymi – szacowana wartość
                                około <strong><span className="text-4xl whitespace-nowrap">2
                                200 000 złotych</span></strong></li>
                        </ul>

                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-5 col-span-10 lg:justify-self-end justify-self-center w-full">
                        {/*<button
                            onClick={() => navigate('/specjalizacje/osoby-fizyczne/postepowanie-sadowe-i-spory-cywilne')}
                            className="btn-nav flex justify-between items-center mb-10">
                            <span>POSTĘPOWANIE SĄDOWE I SPORY CYWILNE</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>*/}
                        <button onClick={() => {
                            navigate('/specjalizacje/osoby-fizyczne/prawo-karne');
                            window.scrollTo(0, 0);
                        }} className="btn-nav flex justify-between items-center mb-10">
                            <span>PRAWO KARNE</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button onClick={() => {
                            navigate('/specjalizacje/osoby-fizyczne/prawo-rodzinne-i-rozwody');
                            window.scrollTo(0, 0);
                        }} className="btn-nav flex justify-between items-center mb-10">
                            <span>PRAWO RODZINNE I ROZWODY</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button onClick={() => {
                            navigate('/specjalizacje/osoby-fizyczne/spadki-i-postepowania-spadkowe');
                            window.scrollTo(0, 0);
                        }} className="btn-nav flex justify-between items-center mb-10">
                            <span>SPADKI I POSTĘPOWANIA SPADKOWE</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button onClick={() => {
                            navigate('/specjalizacje/osoby-fizyczne/windykacja');
                            window.scrollTo(0, 0);
                        }} className="btn-nav flex justify-between items-center mb-10">
                            <span>WINDYKACJA</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button onClick={() => {
                            navigate('/specjalizacje/osoby-fizyczne/ochrona-dobr-osobistych');
                            window.scrollTo(0, 0);
                        }} className="btn-nav flex justify-between items-center mb-10">
                            <span>OCHRONA DÓBR OSOBISTYCH I WŁASNOŚCI INTELEKTUALNEJ</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                        <button onClick={() => {
                            navigate('/specjalizacje/osoby-fizyczne/prawo-pracy');
                            window.scrollTo(0, 0);
                        }} className="btn-nav flex justify-between items-center mb-10">
                            <span>PRAWO PRACY </span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
};