import React, {useEffect, useState} from 'react';
import "./UserDetails.scss"
import {NavLink, useNavigate, useParams} from "react-router-dom";

export const UserDetails = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [userId, setUserId] = useState(id)

    const handleLinkClick = (newUserId) => {
        window.scrollTo(0, 0);
        setUserId(newUserId);
        navigate(`/zespol/${newUserId}`);
    };

    function handleClick() {
        navigate('/');
    }

    useEffect(() => {
        const validIds = [
            'jaroslaw-kowalewski',
            'piotr-kujawa',
            'pawel-balcerkiewicz',
            'aleksandra-karczynska',
            'mikolaj-wojciechowski',
        ];

        if (!validIds.includes(id)) {
            navigate('/page-not-found');
        }
    }, [id, navigate]);

    return (
        <div className="user-details">
            <button className="btn-home" onClick={handleClick}>
                &#171; powrót
            </button>
            <div className="switcher flex justify-around">

                <NavLink to="/zespol/jaroslaw-kowalewski" onClick={() => handleLinkClick('jaroslaw-kowalewski')}
                         isActive={() => {
                             return window.location.pathname.startsWith("/zespol/jaroslaw-kowalewski");
                         }}>Jarosław Kowalewski</NavLink>


                <NavLink to="/zespol/piotr-kujawa" onClick={() => handleLinkClick('piotr-kujawa')} isActive={() => {
                    return window.location.pathname.startsWith("/zespol/piotr-kujawa");
                }}>Piotr Kujawa</NavLink>


                <NavLink to="/zespol/pawel-balcerkiewicz" onClick={() => handleLinkClick('pawel-balcerkiewicz')}
                         isActive={() => {
                             return window.location.pathname.startsWith("/zespol/pawel-balcerkiewicz");
                         }}>Paweł Balcerkiewicz</NavLink>


                <NavLink to="/zespol/mikolaj-wojciechowski" onClick={() => handleLinkClick('mikolaj-wojciechowski')}
                         isActive={() => {
                             return window.location.pathname.startsWith("/zespol/mikolaj-wojciechowski");
                         }}>Mikołaj Wojciechowski</NavLink>

                <NavLink to="/zespol/aleksandra-karczynska" onClick={() => handleLinkClick('aleksandra-karczynska')}
                         isActive={() => {
                             return window.location.pathname.startsWith("/zespol/aleksandra-karczynska");
                         }}>Aleksandra Karczyńska</NavLink>

            </div>
            <div className="user-wrapper">
                {userId === 'jaroslaw-kowalewski' && (
                    <div className="grid grid-cols-9">


                        <div className="lg:col-span-4 col-span-9 lg:mb-0 mb-10 flex lg:justify-start justify-center">
                            <div className="box-wrapper">
                                <div className="flex justify-center">
                                    <img src="/images/team/jaroslaw_kowalewski.png" alt="jarosław kowalewski"/>
                                </div>
                                <div className="flex flex-col justify-center items-center mt-12">
                                    <div className="flex justify-center items-center w-full">
                                        <hr/>
                                        <h4 className="box-name mx-2">Jarosław Kowalewski</h4>
                                        <hr/>
                                    </div>
                                    <p className="mt-6">adwokat</p>
                                    <p className="text-center">Prezes Zarządu Polskiego <br/>
                                        Związku Taekwondo Olimpijskiego</p>
                                    <span className="mt-6">
                                mail: <a href="mailto:j.kowalewski@kwkl.pl"><strong>j.kowalewski@kwkl.pl</strong></a>
                                </span>
                                    <span className="mb-1 ">
                                tel.: <a href="tel:501 039 699"><strong>501 039 699</strong></a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-1 lg:flex hidden"></div>
                        <div className="lg:col-span-4 col-span-9">

                            <p className="box-text">Adwokat wpisany na listę Okręgowej Rady Adwokackiej w Warszawie,
                                posiada również
                                uprawnienia zawodowe radcy prawnego. Cechuje go wieloletnie doświadczenie
                                zawodowe związane z doradztwem dla branży farmaceutycznej, zdobyte zarówno jako
                                prawnik wewnętrzny (członek zespołu prawnego wiodącego producenta leków i
                                suplementów diety - US Pharmacia), jak również doradca zewnętrzny, członek
                                zespołu farmaceutycznego Kancelarii Domański Zakrzewski Palinka. W ramach
                                prowadzonej praktyki doradzał na rzecz wielu podmiotów zajmujących się
                                dystrybucją, wytwarzaniem oraz reklamą produktów leczniczych, wyrobów medycznych
                                kosmetyków oraz suplementów diety.</p>

                            <p className="box-text">Absolwent Wydziału Prawa i Administracji Uniwersytetu Warszawskiego
                                w Warszawie.
                                Absolwent Szkoły Prawa Amerykańskiego - Center for American Legal Studies
                                organizowanej przez Uniwersytet Warszawski oraz University of Florida Levin
                                College of Law oraz Szkoły Prawa Brytyjskiego - British Center for English and
                                European Studies” – organizowanej przez Uniwersytet Warszawski i University of
                                Cambridge. W latach 2012 – 2016 wspólnik wiodącej kancelarii prawnej (kierujący
                                5 osobowym zespołem prawników) odpowiedzialny za praktykę farmaceutyczną oraz
                                prawa karnego.</p>

                            <p className="box-text">Obrońca w procesach karnych, posiadający bogate doświadczenie w
                                prowadzeniu
                                postępowań karnych gospodarczych oraz karno – skarbowych [white collar crimes].
                                Ekspert w zakresie oceny karno – prawnego ryzyka prowadzonej działalności
                                gospodarczej. W swojej praktyce doradzał na rzecz związków sportowych, klubów
                                sportowych oraz towarzystw branżowych. W obszarach jego zainteresowań leżą
                                również kwestie związane z prawem sportowym oraz prowadzeniem sporów sądowych, w
                                tym z zakresu prawa rodzinnego.</p>

                        </div>
                    </div>
                )}
                {userId === 'piotr-kujawa' && (
                    <div className="grid grid-cols-9">


                        <div className="lg:col-span-4 col-span-9 lg:mb-0 mb-10 flex lg:justify-start justify-center">
                            <div className="box-wrapper">
                                <div className="flex justify-center">
                                    <img src="/images/team/piotr_kujawa.png" alt="piotr kujawa"/>
                                </div>
                                <div className="flex flex-col justify-center items-center mt-12">
                                    <div className="flex justify-center items-center w-full">
                                        <hr/>
                                        <h4 className="box-name mx-2">Piotr Kujawa</h4>
                                        <hr/>
                                    </div>
                                    <p className="mt-6">radca prawny</p>

                                    <span className="mt-6">
                                mail: <a href="mailto:p.kujawa@kwkl.pl"><strong>p.kujawa@kwkl.pl</strong></a>
                                </span>
                                    <span className="mb-1 ">
                                tel.: <a href="tel:603 862 304"><strong>603 862 304</strong></a>
                            </span>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-1 lg:flex hidden"></div>
                        <div className="lg:col-span-4 col-span-9">

                            <p className="box-text">Absolwent Wydziału Prawa i Administracji Uniwersytetu Warszawskiego.
                                Radca prawny wpisany na listę radców prawnych prowadzoną przez radę okręgowej izby
                                radców prawnych w Warszawie.</p>

                            <p className="box-text">Od wielu lat zajmuje się obsługą podmiotów działających na rynku
                                farmaceutycznym, w tym producentów leków oraz aptek. W swojej praktyce koncentruje się
                                na zagadnieniach związanych z prowadzeniem obrotu lekami oraz reklamą leków.
                                Specjalizuje się również w konstruowaniu umów handlowych zawieranych pomiędzy
                                uczestnikami obrotu lekami oraz ocenie akcji handlowych prowadzonych na rynku
                                farmaceutycznym. Doradza dystrybutorom wyrobów medycznych, kosmetyków oraz suplementów
                                diety. Przed dołączeniem do zespołu zajmował się kompleksową obsługą prawną jednego z
                                departamentów w Ministerstwie Zdrowia. Posiada ugruntowane doświadczenie z zakresu
                                postępowań i sporów cywilnych oraz gospodarczych. </p>

                        </div>
                    </div>
                )}
                {userId === 'pawel-balcerkiewicz' && (
                    <div className="grid grid-cols-9">


                            <div className="lg:col-span-4 col-span-9 lg:mb-0 mb-10 flex lg:justify-start justify-center">
                                <div className="box-wrapper">
                                <div className="flex justify-center">
                                    <img src="/images/team/pawel_balcerkiewicz.png" alt="pawel balcerkiewicz"/>
                                </div>
                                <div className="flex flex-col justify-center items-center mt-12">
                                    <div className="flex justify-center items-center w-full">
                                        <hr/>
                                        <h4 className="box-name mx-2">Paweł Balcerkiewicz</h4>
                                        <hr/>
                                    </div>
                                    <p className="mt-6">adwokat</p>

                                    <span className="mt-6">
                                mail: <a
                                        href="mailto:p.balcerkiwicz@kwkl.pl"><strong>p.balcerkiewicz@kwkl.pl</strong></a>
                                    </span>
                                    <span className="mb-1 ">
                                tel.: <a href="tel:730 771 732"><strong>730 771 732</strong></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-1 lg:flex hidden"></div>
                        <div className="lg:col-span-4 col-span-9">

                            <p className="box-text">Absolwent Wydziału Prawa i Administracji Uniwersytetu
                                Warszawskiego. Adwokat wpisany na listę Okręgowej Rady Adwokackiej w
                                Warszawie. </p>

                            <p className="box-text">W czasie studiów dwukrotnie otrzymał Stypendium rektora dla
                                najlepszych studentów, świadczenie przyznawane studentom osiągającym najwyższe wyniki w
                                nauce. Odbył praktyki w Departamencie Zagrożonych Należności Gospodarczych Banku
                                Millennium, gdzie uczestniczył w procesach restrukturyzacji i windykacji należności
                                finansowych banku. W swojej dotychczasowej karierze zawodowej pracował w kancelarii
                                adwokackiej zajmującej się postępowaniami sądowymi z zakresu prawa cywilnego oraz prawa
                                karnego, a następnie w kancelarii adwokacko-radcowskiej specjalizującej się w
                                kompleksowej obsłudze prawnej przedsiębiorstw ze szczególnym uwzględnieniem
                                przeprowadzania transakcji sprzedaży nieruchomości oraz procesów budowlanych.</p>
                            <p className="box-text">
                                Z kancelarią KWKL Kowalewski Legal jest związany od początku aplikacji adwokackiej.
                                Swoje zainteresowania zawodowe koncentruje wokół postępowań sądowych z zakresu prawa
                                gospodarczego i roszczeń majątkowych oraz postępowań karnych. Posiada również
                                doświadczenie w prowadzeniu sporów sądowych z zakresu prawa rodzinnego.
                            </p>

                        </div>
                    </div>
                )}
                {userId === 'aleksandra-karczynska' && (
                    <div className="grid grid-cols-9">


                            <div className="lg:col-span-4 col-span-9 lg:mb-0 mb-10 flex lg:justify-start justify-center">
                                <div className="box-wrapper">
                                <div className="flex justify-center">
                                    <img src="/images/team/aleksandra_karczynska.png" alt="aleksandra karczynska"/>
                                </div>
                                <div className="flex flex-col justify-center items-center mt-12">
                                    <div className="flex justify-center items-center w-full">
                                        <hr/>
                                        <h4 className="box-name mx-2">Aleksandra Karczyńska</h4>
                                        <hr/>
                                    </div>
                                    <p className="mt-6 text-center">Psycholog, psychoterapeuta, <br/> mediator rodzinny
                                    </p>

                                    <span className="mt-6">
                                mail: <a
                                        href="mailto:a.karczynska@kwkl.pl"><strong>a.karczynska@kwkl.pl</strong></a>
                                </span>
                                    <span className="mb-1 ">
                                tel.: <a href="tel:501 704 347"><strong>501 704 347</strong></a>
                            </span>
                                </div>
                            </div>

                        </div>
                        <div className="lg:col-span-1 lg:flex hidden"></div>
                        <div className="lg:col-span-4 col-span-9">

                            <p className="box-text">Absolwentka Wydziału Psychologii na Uniwersytecie SWPS oraz
                                czteroletniej Szkoły Psychoterapii Psychodynamicznej i Studium Socjoterapii i Treningu
                                Interpersonalnego w Krakowskim Centrum Psychodynamicznym, rekomendowanym przez Polskie
                                Towarzystwo Psychologiczne. Członek Polskiego Towarzystwa Psychoterapii
                                Psychodynamicznej.</p>

                            <p className="box-text">Posiada wieloletnie doświadczenie w pracy psychologa, terapeuty,
                                zdobyte w placówkach oświatowych oraz ośrodkach wsparcia dla młodzieży i rodziny. W
                                praktyce zawodowej, zajmuje się pracą z dziećmi i rodzinami pozostającymi w kryzysie.
                                Udziela pomocy i wsparcia dorosłym, borykającym się
                                z problemami w związku (separacja/rozwód) i trudnościami wychowawczymi związanymi
                                z rodzicielstwem, okresem dorastania.
                            </p>
                            <p className="box-text">
                                W swojej codziennej praktyce zajmuje się również wspomaganiem rozwoju osobistego kadry
                                kierowniczej i menadżerskiej oraz terapią osób dorosłych i młodzieży.
                            </p>

                        </div>
                    </div>
                )}
                {userId === 'mikolaj-wojciechowski' && (
                    <div className="grid grid-cols-9">


                            <div className="lg:col-span-4 col-span-9 lg:mb-0 mb-10 flex lg:justify-start justify-center">
                                <div className="box-wrapper">
                                <div className="flex justify-center">
                                    <img src="/images/team/mikolaj_wojciechowski.png" alt="mikołaj wojciechowski"/>
                                </div>
                                <div className="flex flex-col justify-center items-center mt-12">
                                    <div className="flex justify-center items-center w-full">
                                        <hr/>
                                        <h4 className="box-name mx-2">Mikołaj Wojciechowski</h4>
                                        <hr/>
                                    </div>
                                    <p className="mt-6">adwokat</p>
                                    <p className="text-center">Przewodniczący Rady Nadzorczej <br/> PCF Group S.A. z
                                        siedzibą w Warszawie </p>

                                    <span className="mt-6">
                                mail: <a
                                        href="mailto:m.wojciechowski@kwkl.pl"><strong>m.wojciechowski@kwkl.pl</strong></a>
                                </span>
                                    <span className="mb-1 ">
                                tel.: <a href="tel:694 938 328"><strong>694 938 328</strong></a>
                            </span>
                                </div>
                            </div>

                        </div>
                        <div className="lg:col-span-1 lg:flex hidden"></div>
                        <div className="lg:col-span-4 col-span-9">

                            <p className="box-text">Absolwent Wyższej Szkoły Prawa i Administracji w Warszawie. Adwokat
                                wpisany na listę Okręgowej Rady Adwokackiej w Warszawie, którego cechuje 11-letnie
                                doświadczenie zawodowe. Posiada bogate doświadczenie w zakresie pełnienia roli
                                pełnomocnika procesowego. Reprezentuje klientów przed sądami powszechnymi, sądami
                                administracyjnymi oraz Sądem Najwyższym. Posiada ekspercką wiedzę z zakresu prowadzenia
                                sporów sądowych ze szczególnym uwzględnieniem postępowań karnych oraz karnych
                                gospodarczych. Do jego zainteresowań zalicza się prawo cywilne i rodzinne. </p>
                        </div>
                    </div>
                )}
            </div>


        </div>
    )
}