import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Header} from "./components/Header/Header";
import {Footer} from "./components/Footer/Footer";
import {MainPage} from "./pages/MainPage";
import {Divorces} from "./pages/Divorces";
import {Contact} from "./pages/Contact";
import {UserDetails} from "./pages/UserDetails";
import {PhysicalUsers} from "./pages/PhysicalUsers/PhysicalUsers";
import {Firms} from "./pages/Firms/Firms";
import {JudicialProceeding} from "./pages/PhysicalUsers/JudicialProceeding";
import {CriminalLow} from "./pages/PhysicalUsers/CriminalLow";
import {FamilyLow} from "./pages/PhysicalUsers/FamilyLow";
import {Declines} from "./pages/PhysicalUsers/Declines";
import {Vindication} from "./pages/PhysicalUsers/Vindication";
import {Protection} from "./pages/PhysicalUsers/Protection";
import {LaborLow} from "./pages/PhysicalUsers/LaborLow";
import {Pharmacy} from "./pages/Firms/Pharmacy";
import {DisputesOrchard} from "./pages/Firms/DisputesOrchard";
import {VindicationFirms} from "./pages/Firms/VindicationFirms";
import {CriminalLowFirms} from "./pages/Firms/CriminalLowFirms";
import {ContractLow} from "./pages/Firms/ContractLow";
import {SportLow} from "./pages/Firms/SportLow";
import {Support} from "./pages/Firms/Support";
import {NotFound} from "./pages/NotFound";
import {useState} from "react";

function App() {

    const [show, setShow]= useState(true);
    return (
        <>
            <BrowserRouter>
                {show && <Header />}
                <Routes>
                    <Route exact path="/" element={<MainPage/>} />
                    <Route path="rozwody" element={<Divorces />} />
                    <Route path="kontakt" element={<Contact />} />
                    <Route exact path="/zespol/:id" element={<UserDetails />}/>
                    <Route path="specjalizacje/osoby-fizyczne" element={<PhysicalUsers />}/>
                    <Route path="specjalizacje/osoby-fizyczne/postepowanie-sadowe-i-spory-cywilne" element={<JudicialProceeding />}/>
                    <Route path="specjalizacje/osoby-fizyczne/prawo-karne" element={<CriminalLow />}/>
                    <Route path="specjalizacje/osoby-fizyczne/prawo-rodzinne-i-rozwody" element={<FamilyLow />}/>
                    <Route path="specjalizacje/osoby-fizyczne/spadki-i-postepowania-spadkowe" element={<Declines />}/>
                    <Route path="specjalizacje/osoby-fizyczne/windykacja" element={<Vindication />}/>
                    <Route path="specjalizacje/osoby-fizyczne/ochrona-dobr-osobistych" element={<Protection />}/>
                    <Route path="specjalizacje/osoby-fizyczne/prawo-pracy" element={<LaborLow />}/>
                    <Route path="specjalizacje/przedsiebiorcy" element={<Firms />}/>
                    <Route path="specjalizacje/przedsiebiorcy/farmacja-i-compliance" element={<Pharmacy />}/>
                    <Route path="specjalizacje/przedsiebiorcy/spory-sadowe-i-arbitraz" element={<DisputesOrchard />}/>
                    <Route path="specjalizacje/przedsiebiorcy/windykacja-i-wywiad-gospodarczy" element={<VindicationFirms />}/>
                    <Route path="specjalizacje/przedsiebiorcy/prawo-karne-gospodarcze" element={<CriminalLowFirms />}/>
                    <Route path="specjalizacje/przedsiebiorcy/prawo-kontraktowe" element={<ContractLow />}/>
                    <Route path="specjalizacje/przedsiebiorcy/prawo-sportowe-i-sport-management" element={<SportLow />}/>
                    <Route path="specjalizacje/przedsiebiorcy/wsparcie-prawne-dla-fundacji-i-stowarzyszen" element={<Support />}/>

                    <Route path="*" element={<NotFound setShow={setShow}/>} />
                </Routes>
                {show && <Footer />}

            </BrowserRouter>
        </>
    );
}

export default App;
