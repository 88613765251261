import React, {useEffect} from 'react';
import "./NotFound.scss";
import {NavLink} from "react-router-dom";

export const NotFound = ({setShow}) => {
    useEffect(() => {
        setShow(false)
    }, [setShow])
    return (
        <div className="flex justify-center items-center h-screen">
            <div className="flex flex-col justify-center items-center">
                <h1 className="title-404">404</h1>
                <h3>Page Not Found</h3>
                <p>
                    <NavLink to="/" onClick={() => {
                        setShow(true)
                    }}>Go Home</NavLink>
                </p>
            </div>
        </div>
    );
};

