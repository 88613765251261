import React from 'react';
import "../Divorces.scss"
import "../PhysicalUsers/PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const Pharmacy = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Przedsiębiorcy
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-9">
                        <h3 className="specialization-title mb-20">
                            FARMACJA & COMPLIANCE
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col justify-self-start w-full">


                        <p className="text-justify mb-5">Nasze doświadczenie w zakresie znajomości branży
                            farmaceutycznej jest sumą doświadczeń naszych prawników zdobytych w pracy z producentami
                            leków, hurtowniami oraz aptekami. Zdobyliśmy je pracując dla naszych klientów jako doradcy
                            wewnętrzni (in-house) oraz jako niezależny zewnętrzny zespół ekspertów. Mamy pewność, iż
                            szersze, zewnętrzne oraz niezależne spojrzenie na zachodzące procesy rynkowe, pozwala na
                            właściwą ocenę środowiska biznesowego podmiotów działających na tym rynku.

                        </p>
                        <p className="text-justify mb-5">
                            Znając specyfikę oraz potrzeby biznesowe podmiotów działających na rynku regulowanym znamy
                            jego ograniczenia, a naszą usługę doradztwa zawsze dostosowujemy do oczekiwań i bieżących
                            potrzeb naszych klientów.
                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Prowadziliśmy projekty związane z: </h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>tworzeniem oraz wprowadzeniem w życie procedur wewnętrznych związanych z działaniami
                                marketingowymi;
                            </li>
                            <li>oceną akcji oraz kampanii reklamowych produktów leczniczych;
                            </li>
                            <li>analizą oraz planowaniem systemu dystrybucji produktów leczniczych;
                            </li>
                            <li>audytami prowadzonej działalności marketingowej;</li>
                            <li>szkoleniami zamkniętymi dla kadry menadżerskiej związanymi z odpowiedzialnością
                                menadżera w spółce farmaceutycznej, procedurami antykorupcyjnymi oraz analizą ryzyka
                                biznesowego.
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col lg:justify-self-start w-full">
                        <img src="/images/4.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};