import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

export const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickPhysical = () => {
        navigate('/specjalizacje/osoby-fizyczne');
        window.scrollTo(0, 0);
    }
    const handleClickFirms = () => {
        navigate('/specjalizacje/przedsiebiorcy');
        window.scrollTo(0, 0);
    }

    const buttonsPhysical = [
        {
            label: 'PRAWO KARNE',
            path: '/specjalizacje/osoby-fizyczne/prawo-karne',
        },
        {
            label: 'PRAWO RODZINNE I ROZWODY',
            path: '/specjalizacje/osoby-fizyczne/prawo-rodzinne-i-rozwody',
        },
        {
            label: 'SPADKI I POSTĘPOWANIA SPADKOWE',
            path: '/specjalizacje/osoby-fizyczne/spadki-i-postepowania-spadkowe',
        },
        {
            label: 'WINDYKACJA',
            path: '/specjalizacje/osoby-fizyczne/windykacja',
        },
        {
            label: 'OCHRONA DÓBR OSOBISTYCH I WŁASNOŚCI INTELEKTUALNEJ',
            path: '/specjalizacje/osoby-fizyczne/ochrona-dobr-osobistych',
        },
        {
            label: 'PRAWO PRACY',
            path: '/specjalizacje/osoby-fizyczne/prawo-pracy',
        },

    ];

    const buttonsFirms = [
        {
            label: 'FARMACJA | COMPLIANCE',
            path: '/specjalizacje/przedsiebiorcy/farmacja-i-compliance',
        },
        {
            label: 'SPORY SĄDOWE I ARBITRAŻ',
            path: '/specjalizacje/przedsiebiorcy/spory-sadowe-i-arbitraz',
        },
        {
            label: 'WINDYKACJA I WYWIAD GOSPODARCZY',
            path: '/specjalizacje/przedsiebiorcy/windykacja-i-wywiad-gospodarczy',
        },
        {
            label: 'PRAWO KARNE GOSPODARCZE',
            path: '/specjalizacje/przedsiebiorcy/prawo-karne-gospodarcze',
        },
        {
            label: 'PRAWO SPORTOWE I SPORT MANAGEMENT',
            path: '/specjalizacje/przedsiebiorcy/prawo-sportowe-i-sport-management',
        },
    ]

    return (
        <>
            <div className="flex justify-center items-center lg:mt-40 mt-20">
                <hr/>
                <h2 className="page-title">
                    Specjalizacje
                </h2>
                <hr/>
            </div>
            <div className="grid grid-cols-9 lg:px-48">
                <div className="lg:col-span-4 col-span-9 lg:justify-self-end w-full mt-20">
                    <div className="lg:col-span-4 col-span-5 flex justify-center">
                        <button onClick={handleClickPhysical}
                                className="btn-spec flex justify-center items-center mt-20  mb-20">
                            <span>OSOBY FIZYCZNE</span>
                        </button>
                    </div>

                    {buttonsPhysical.map((button, index) => (
                        <button
                            key={index}
                            className={`btn-nav flex justify-between items-center mb-10 ${location.pathname === button.path ? 'active-color' : ''}`}
                            onClick={() => {
                                navigate(button.path);
                                window.scrollTo(0, 0);
                            }}
                        >
                            <span>{button.label}</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                    ))}
                </div>
                    <div className="col-span-1"></div>
                <div className="lg:col-span-4 col-span-9 lg:justify-self-end w-full mt-20">
                    <div className="lg:col-span-4 col-span-9 flex justify-center">
                        <button
                            onClick={handleClickFirms}
                            className="btn-spec flex justify-center items-center mt-20  mb-20">
                            <span>PRZEDSIĘBIORCY</span>
                        </button>
                    </div>

                    {buttonsFirms.map((button, index) => (
                        <button
                            key={index}
                            className={`btn-nav flex justify-between items-center mb-10 ${location.pathname === button.path ? 'active-color' : ''}`}
                            onClick={() => {
                                navigate(button.path);
                                window.scrollTo(0, 0);
                            }}
                        >
                            <span>{button.label}</span>
                            <img src="/images/arrow-right.png" alt="arrow right"/>
                        </button>
                    ))}</div>
                
            </div>
        </>
    );
}
