import React from 'react';
import "../Divorces.scss"
import "../PhysicalUsers/PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const CriminalLowFirms = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Przedsiębiorcy
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 ">
                    <div className="lg:col-span-4 col-span-9">
                        <h3 className="specialization-title mb-20">
                            PRAWO KARNE GOSPODARCZE
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col justify-self-start w-full">

                        <p className="text-justify mb-5">Posiadamy unikatowe doświadczenie w zakresie prowadzenia
                            postępowań karnych oraz karno – skarbowych. Zrealizowaliśmy z sukcesem wiele projektów dla
                            businessu, gdzie nasza wiedza oraz umiejętności praktyczne przyczyniły się do wygrania sporu
                            lub zminimalizowania skutków podjętych przez przedsiębiorcę bądź managera decyzji. Nasze
                            bogate doświadczenie, ugruntowana pozycja rynkowa jak i dostęp do szeregu specjalistów z
                            poszczególnych dziedzin prawa gospodarczego, dają gwarancję podejmowania przez
                            przedsiębiorcę decyzji bussinesowych w sposób możliwie jak najbardziej bezpieczny przy
                            uwzględnieniu specyfiki ryzyka odpowiedzialności karnej. Jest to tym bardziej istotne, że w
                            ramach struktur korporacyjnych, odpowiedzialność karna, czy też karnoskarbowa co do zasady
                            będzie kierowana w pierwszej kolejności do osób reprezentujących interesy danej firmy.
                            Znajomość specyfiki prowadzenia postępowań karnych oraz karno – skarbowych, w powiązaniu ze
                            znajomością funkcjonowania przedsiębiorcy, pozwala nam na pełną analizę oraz ocenę ryzyka
                            związanego z możliwością poniesienia przez przedsiębiorcę odpowiedzialności karnej w danym
                            środowisku bussinesowym: farmacja, ochrona zdrowia, podatki, przetargi publiczne, prawo
                            budowlane, ochrona środowiska, przestępstwa urzędnicze.

                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Nasi prawnicy z powodzeniem realizowali projekty
                            związane z: </h2>

                        <ul className="scope mb-15 text-justify ml-5">
                            <li>reprezentowaniem członka zarządu spółki developerskiej w sprawie działania na szkodę
                                spółki;
                            </li>
                            <li>reprezentowaniem członka zarządu spółki kapitałowej w sprawie dotyczącej manipulowania
                                akcjami spółki akcyjnej będącymi w publicznym obrocie;
                            </li>
                            <li>reprezentowaniem właścicieli spółki osobowej w sporze z organami skarbowymi;
                            </li>
                            <li>reprezentowaniem urzędnika samorządowego w sprawie o przekroczenie uprawnień oraz
                                przestępstwa korupcyjne;
                            </li>
                            <li> reprezentowaniem pracowników spółki farmaceutycznej oskarżonych o korumpowanie lekarzy
                                i farmaceutów;
                            </li>
                            <li>wszczynaniem i uczestniczeniem w sprawach działania na szkodę interesów spółki przez
                                jej personel zarządzający i kadrę pracowniczą;
                            </li>
                            <li> reprezentowaniem członka zarządu spółki kapitałowej w sprawie tzw. korupcji
                                managerskiej.
                            </li>
                        </ul>
                        <p className="text-justify mb-5">Nasi prawnicy wspierają przedsiębiorców w trakcie czynności
                            procesowych organów ścigania: przeszukania w siedzibie spółki, zabezpieczanie dowodów
                            przestępstw.</p>
                        <p className="text-justify mb-20">
                            Realizowaliśmy z powodzeniem szereg projektów szkoleniowych z zakresu zwalczania korupcji w
                            sektorze farmaceutycznym. Szkoliliśmy managerów oraz pracowników niższego szczebla z zakresu
                            ich uprawnień procesowych, czynności operacyjno – rozpoznawczych Policji, CBA i innych
                            służb.
                        </p>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col lg:justify-self-start w-full">
                        <img src="/images/2.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};