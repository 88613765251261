import React from 'react';
import Slider from "react-slick";

export const Trust = () => {
    const settingsLogo = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
        ]
    };
    return (
        <div>
            <div className="trust-wrapper mx-5">
                <div className="flex justify-center items-center mb-16">
                    <hr/>
                    <h3 className="team-title">
                        Zaufali nam
                    </h3>
                    <hr/>
                </div>

                <Slider {...settingsLogo}>
                    <div className="image-logo flex justify-center"><img src="/images/logo/ART.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/AVALON.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/ENERJI.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/KLTP-P.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/NCM.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/PAB.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/PHARMA.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/PLAY.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/PZTO.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/SCANIA.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/SPRD.png" alt="adamed logo"/>
                    </div>
                    <div className="image-logo flex justify-center"><img src="/images/logo/WS.png" alt="adamed logo"/>
                    </div>
                </Slider>
            </div>
        </div>
    );
};