import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const FamilyLow = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                            PRAWO RODZINNE I ROZWODY
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col lg:justify-self-start w-full">


                        <p className="text-justify mb-5">Stworzyliśmy dla Państwa silną praktykę doradztwa dla rodziny
                            obejmującą wsparcie z zakresu prawa rodzinnego i opiekuńczego, a także dysfunkcji oraz
                            rozkładu rodziny. Nasze doradztwo skupia się na ratowaniu rodziny oraz wydobywaniu jej z
                            kryzysu. Zapewniamy wsparcie prawne we wszystkich ważnych aspektach funkcjonowania rodziny:
                            problemów wychowawczych dzieci, problemów w relacjach rodzinnych, czy też przyczyn rozpadu
                            związków małżeńskich.
                        </p>

                        <p className="text-justify mb-5">
                            Korzystając z wiedzy i doświadczenia naszego zespołu prawników oraz współpracujących z nimi
                            psychologów macie Państwo pewność, że zlecone nam zagadnienia będą realizowane w oparciu
                            o najwyższe standardy merytoryczne i osobiste zaangażowanie.

                        </p>
                        <p className="text-justify mb-5">
                            Do każdej sprawy podchodzimy indywidualnie dostosowując taktykę i strategię oraz niezbędne
                            środki do szczególnych potrzeb naszych klientów.
                        </p>
                        <p className="text-justify mb-5">
                            Nasi prawnicy z powodzeniem przeprowadzili kilkadziesiąt postępowań rozwodowych oraz wiele
                            udanych mediacji rodzinnych.
                        </p>
                        <p className="text-justify mb-5">
                            Specjalnie dla Państwa stworzyliśmy dwa odrębne projekty dedykowane prawu rodzinnemu oraz
                            rozwodom KWKL Rozwój i Rodzina oraz Get a Divorce.
                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Realizujemy doradztwo prawne z zakresu: </h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>spraw o rozwód i separację
                            </li>
                            <li>spraw dotyczących zniesienia wspólnoty majątkowej małżeńskiej
                            </li>
                            <li>podziału majątku wspólnego małżonków po ustaniu wspólnoty majątkowej małżeńskiej
                            </li>
                            <li>spraw alimentacyjnych </li>
                            <li>spraw dotyczące władzy rodzicielskiej  </li>
                            <li>spraw o ustalenie ojcostwa, unieważnienie małżeństwa, uznania dziecka oraz przysposobienia </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col justify-self-start w-full">
                        <img src="/images/1.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};