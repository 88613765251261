import React from 'react';
import "../Divorces.scss"
import "./PrhysicalUsers.scss"
import {Navigation} from "../../components/Navigation";

export const LaborLow = () => {

    return (
        <>
            <div className="page-wrapper">
                <div className="flex justify-center items-center lg:mb-36 mb-20">
                    <hr/>
                    <h2 className="page-title">
                        Osoby fizyczne
                    </h2>
                    <hr/>
                </div>
                <div className="grid grid-cols-9 lg:px-48 px-0">
                    <div className="lg:col-span-4 col-span-10">
                        <h3 className="specialization-title mb-20">
                            PRAWO PRACY I UBEZPIECZENIA SPOŁECZNE
                        </h3>
                    </div>
                    <div className="lg:col-span-5 lg:flex hidden"></div>
                    <div className="lg:col-span-4 col-span-9 flex flex-col lg:justify-self-start w-full">


                        <p className="text-justify mb-5">
                            Prawnicy KWKL świadczą kompleksowe doradztwo prawne dla pracowników z zakresu prawa pracy
                            a dotyczących m.in. umów o pracę, kontraktów menedżerskich, sporów sądowych z pracodawcą,
                            a także ubezpieczeń społecznych. Nasze działania obejmują prowadzenie spraw sądowych
                            dotyczących w szczególności: spraw o zapłatę wynagrodzenia, godzin nadliczbowych, uchylania
                            się przez pracodawcę z obowiązku wypłaty dodatkowych świadczeń

                        </p>

                        <h2 className="divorces-title mb-10 mt-10"> Zrealizowaliśmy m.in. następujące projekty:</h2>

                        <ul className="scope mb-20 text-justify ml-5">
                            <li>reprezentacja pracownika w sporze z pracodawcą związanym z ustaleniem stosunku pracy;
                            </li>
                            <li>reprezentacja pracownika w sporze z pracodawcą – spór dotyczył wypłaty należnego
                                pracownikowi wynagrodzenia z tytułu godzin nadliczbowych;
                            </li>
                            <li> uzyskanie odszkodowania od pracodawcy w związku z bezprawnym rozwiązaniem umowy
                                o pracę dla pracownika sektora farmaceutycznego;
                            </li>
                            <li>reprezentacja pracownika w sporze z pracodawcą w zakresie roszczeń z tytułu
                                dyskryminacji i mobbingu;
                            </li>
                            <li>ustalenie w sporze z ZUS właściwej podstawy wymiaru składek ubezpieczeniowych
                                należnych pracownikowi.
                            </li>
                        </ul>

                    </div>
                    <div className="lg:col-span-1 justify-self-end"></div>
                    <div className="lg:col-span-4 col-span-9 lg:order-last order-first lg:mb-0 mb-20 flex flex-col justify-self-start w-full">
                        <img src="/images/1.png" alt="osoby fizyczne"/>
                    </div>


                </div>
                <Navigation />
            </div>
        </>
    );
};