import React from 'react';
import "./Footer.scss"
import {NavLink} from "react-router-dom";

export const Footer = () => {
    const numb = 531580793;
    const numbFmt = new Intl.NumberFormat('ru-RU').format(numb);


    return (
        <footer>
            <div className="footer-container">
                <div>
                    <NavLink to="/" onClick={()=> window.scrollTo(0, 0)}><img src="/images/logo.png" alt="logo"/></NavLink>
                </div>
                <div className="grid grid-cols-7">
                    <div className="lg:col-span-4 col-span-7">
                        <div className="grid lg:grid-cols-2 grid-cols-4 pt-28">
                            <div className="lg:col-span-1 col-span-3 flex flex-col">
                            <span className="footer-title mb-10">
                                KWKL Kowalewski Legal
                            </span>
                                <span className="mb-1 footer-text">
                               ul. Żegańska 16 lok. 2
                            </span>
                                <span className="mb-5 footer-text">
                                04-713 Warszawa
                            </span>
                                <span className="mb-1 footer-text">
                                tel.: <a href="tel:531 580 793"><strong>{numbFmt}</strong></a>
                            </span>
                                <span className="footer-text">
                                mail: <a href="mailto:biuro@kwkl.pl"><strong>biuro@kwkl.pl</strong></a>
                            </span>
                            </div>
                            <div className="col-span-1 lg:self-start self-center">
                                <div className="col-span-1 lg:items-start items-end flex flex-col">
                            <span className="footer-title mb-10 lg:flex hidden">
                                Znajdź nas
                            </span>
                                    <a href="https://www.facebook.com/KWKLLegal/" target="_blank" rel="noreferrer" className="mb-2 inline-flex footer-text">
                                        <div className="pt-1 mr-4">
                                            <img src="/images/FACEBOK%201.png" alt="facebook" className="lg:block hidden"/>
                                            <img src="/images/mobile/FACEBOK%201.png" alt="facebook" className="lg:hidden"/>
                                        </div>
                                        <span className="lg:flex hidden">FACEBOOK</span>
                            </a>
                                    <a href="https://twitter.com/adw_Kowalewski" target="_blank" rel="noreferrer" className="mb-2 inline-flex footer-text">
                                        <div className="pt-1 mr-4">
                                            <img src="/images/TWITTER%201.png" alt="twitter" className="lg:block hidden"/>
                                            <img src="/images/mobile/TWITTER%201.png" alt="twitter" className="lg:hidden"/>
                                        </div>
                                        <span className="lg:flex hidden">TWITTER</span>
                            </a>
                                    <a href="https://www.linkedin.com/company/kwkl-kowalewski-legal/?originalSubdomain=pl" target="_blank" rel="noreferrer" className="inline-flex footer-text">
                                        <div className="pt-1 mr-4">
                                            <img src="/images/LINKEDIN%201.png" alt="facebook" className="lg:block hidden"/>
                                            <img src="/images/mobile/LINKEDIN%201.png" alt="facebook" className="lg:hidden"/>
                                        </div>
                                        <span className="lg:flex hidden">LINKEDIN</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-3 lg:flex hidden lg:mt-0 mt-16">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9780.347199601465!2d21.1730515!3d52.2054701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ed301440e5555%3A0xc0f611b2b8fa96ae!2sKWKL%20Kowalewski%20Legal%20Adwokat%20Jaros%C5%82aw%20Kowalewski!5e0!3m2!1spl!2spl!4v1678712587594!5m2!1spl!2spl"
                            allowFullScreen="" loading="lazy" width="100%" height="100%" title="kwkl"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>

            <div className="lg:hidden mt-16">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9780.347199601465!2d21.1730515!3d52.2054701!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ed301440e5555%3A0xc0f611b2b8fa96ae!2sKWKL%20Kowalewski%20Legal%20Adwokat%20Jaros%C5%82aw%20Kowalewski!5e0!3m2!1spl!2spl!4v1678712587594!5m2!1spl!2spl"
                    allowFullScreen="" loading="lazy" width="100%" height="250px" title="kwkl"
                    referrerPolicy="no-referrer-when-downgrade">

                </iframe>
            </div>
        </footer>
    );
};